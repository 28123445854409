<template>
  <v-container class="py-4">
    <v-form v-model="valid.address" v-if="!fetchedAddress">
      <v-row>
        <v-col cols="12" md="6" class="py-0">
          <v-select
            outlined
            :label="$t('components.address_expansion_panel.country')"
            v-model="selectedCountry"
            :items="enumModels.Country"
            :item-text="$vuetify.lang.current === 'en' ? 'textEn' : 'textFr'"
            item-value="value"
            :rules="[$rules.required]"
            @change="otherCountry = null"
            class="required"
            return-object
            clearable
          ></v-select>
        </v-col>
        <template v-if="selectedCountry">
          <!-- <v-col cols="12" md="6" v-if="selectedCountry.isOther">
            <no-autocomplete-textfield
              :label="$t('components.address_expansion_panel.other_country')"
              v-model="otherCountry"
              class="required"
              :rules="[$rules.required]"
            ></no-autocomplete-textfield>
          </v-col> -->
          <v-col cols="12" md="6" class="py-0" v-if="selectedCountry.isOther">
            <v-autocomplete
              outlined
              clearable
              :items="countries"
              :label="$t('components.address_expansion_panel.other_country')"
              :rules="[$rules.required]"
              v-model="otherCountry"
              item-text="text"
              item-value="value"
              class="required errors-msg"
              @change="selectedAddress = null"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            :md="selectedCountry?.code === 'Other' ? 12 : 6"
            class="py-0 text--secondary pb-2"
            v-if="
              (selectedCountry.isOther && otherCountry !== null) ||
              !selectedCountry.isOther
            "
          >
            <v-autocomplete
              :label="
                $t(
                  'components.address_expansion_panel.start_typing_your_address'
                )
              "
              v-model="selectedAddress"
              :items="searchResults"
              return-object
              :loading="isAddressSearchLoading"
              :search-input.sync="searchValue"
              item-text="Description"
              item-value="Id"
              clearable
              append-outer-icon="mdi-magnify"
              ref="addressList"
            ></v-autocomplete>
          </v-col>
        </template>
      </v-row>
    </v-form>
    <v-row v-else-if="fetchedAddress">
      <v-col cols="12" md="6" class="py-0" style="margin-bottom: 20px">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="py-0 font-weight-bold text--secondary">
            {{ $t("components.address_expansion_panel.address") }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="py-0 text--secondary">
            <span class="text-subtitle-1">
              {{ fetchedAddress }}
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="py-0 pb-2">
            <v-btn
              text
              color="primary"
              @click="
                oldFetchedAddress = fetchedAddress;
                fetchedAddress = undefined;
                selectedAddress = null;
                searchResults = [];
              "
            >
              <span class="font-weight-bold">
                {{
                  fetchedAddress === ""
                    ? $t("components.address_expansion_panel.add_address")
                    : $t("components.address_expansion_panel.change_address")
                }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="12" md="6" class="py-0">
        <div
          style="word-wrap: break-word; white-space: pre-wrap; max-width: 200px"
        >
          
        </div>
        
      </v-col> -->
    </v-row>
    <v-form v-model="valid.contactInfo" v-if="internalMember" class="mt-2">
      <v-row>
        <v-col cols="12" md="4" class="py-0">
          <no-autocomplete-textfield
            disabled
            outlined
            :label="
              $t(
                'components.registration_completion.contacts_form.email_readonly'
              )
            "
            v-model="internalMember.EmailAddress"
            :rules="[$rules.required, $rules.email]"
            class="required highlight"
          ></no-autocomplete-textfield>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
          v-if="checkVisible('Phone Number')"
        >
          <no-autocomplete-textfield
            clearable
            outlined
            :label="
              $t('components.contact_information_expansion_panel.telephone')
            "
            v-model="internalMember.HomePhone"
            :rules="
              checkRequired('Phone Number')
                ? [rules.phone, $rules.required]
                : [rules.phone]
            "
            v-mask="telephoneMask"
            :class="checkRequired('Phone Number') ? 'required' : ''"
          ></no-autocomplete-textfield>
        </v-col>
        <v-col cols="12" md="4" class="py-0" v-if="hasPensionPlan">
          <v-select
            outlined
            :label="
              $t('components.contact_information_expansion_panel.phone_type')
            "
            class="required"
            v-model="internalMember.PreferredPhone"
            :rules="[$rules.required]"
            :items="phoneTypes"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
import { mapGetters, mapActions } from "vuex";
import { tokens, mask } from "vue-the-mask";
import masker from "vue-the-mask/src/masker";
import countries from "@/countries";
export default {
  components: { NoAutocompleteTextfield },
  props: {
    saving: Boolean,
    address: Object,
    phone: String,
    formFields: Array,
  },
  directives: { mask },
  data() {
    return {
      internalMember: {},
      valid: {
        contactInfo: false,
        address: false,
      },
      selectedCountry: undefined,
      countries: countries,
      searchValue: undefined,
      searchResults: [],
      selectedAddress: undefined,
      isAddressSearchLoading: false,
      isAddressRetrieveLoading: false,
      oldFetchedAddress: undefined,
      fetchedAddress: undefined,
      canadaPostAddress: undefined,
      telephoneMask: "(###) ### - ####",
      rules: {
        phone: (v) => {
          let val = this.unmask(v, this.telephoneMask);
          return !val || this.$rules.phone(val);
        },
      },
      otherCountry: null,
    };
  },
  computed: {
    ...mapGetters("common", ["enumModels", "identityUser", "settings"]),
    ...mapGetters("auth", ["user"]),
    hasPensionPlan() {
      var hasNeospinMemberId = this.identityUser?.PlanMemberships?.some(
        (o) => o.NeospinMemberId !== null
      );
      return hasNeospinMemberId;
    },
    phoneTypes() {
      const en = [
        {
          text: "Mobile",
          value: "Mobile",
        },
        {
          text: "Home",
          value: "Home",
        },
      ];
      const fr = [
        {
          text: "Mobile",
          value: "Mobile",
        },
        {
          text: "Téléphone",
          value: "Home",
        },
      ];
      return this.$vuetify.lang.current === "fr" ? fr : en;
    },
  },
  watch: {
    internalMember: {
      handler(v) {
        if (
          this.internalMember.HomePhone !== null &&
          this.internalMember.HomePhone !== undefined
        ) {
          v.HomePhone = this.unmask(
            this.internalMember.HomePhone,
            this.telephoneMask
          );
        }
        this.$emit("update-member", v);
      },
      deep: true,
      immediate: true,
    },
    // valid: {
    //   handler(v) {
    //     this.$emit("update-valid", v);
    //   },
    // },
    "valid.contactInfo": {
      handler() {
        // console.log(v);
        this.$emit(
          "update-valid",
          this.valid.contactInfo && this.valid.address && !!this.fetchedAddress
        );
      },
    },
    "valid.address": {
      handler() {
        // console.log(v);
        this.$emit(
          "update-valid",
          this.valid.contactInfo && this.valid.address && !!this.fetchedAddress
        );
      },
    },
    fetchedAddress: {
      handler(v) {
        console.log("fetched changed", v);
        // this.valid.contactInfo = false;
        // this.valid.address = false;
        this.$emit(
          "update-valid",
          this.valid.contactInfo && this.valid.address && !!this.fetchedAddress
        );
      },
      immediate: true,
    },
    searchValue: {
      async handler(v) {
        // console.log(v);
        if (v === null) {
          return;
        }

        if (this.isAddressSearchLoading) {
          return;
        }
        this.isAddressSearchLoading = true;
        try {
          this.searchResults = (
            await this.canadaPostFind({
              payload: {
                Key: this.settings.CanadaPostKey,
                SearchTerm: this.searchValue,
                Country: this.selectedCountry.isOther
                  ? this.otherCountry
                  : this.selectedCountry.code,
                LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
              },
            })
          ).data.Items.map((o) => {
            o.Description = `${o.Text} ${o.Description}`;
            return o;
          });
          // console.log(this.searchResults);
        } catch (error) {
          console.log(error);
        } finally {
          this.isAddressSearchLoading = false;
        }
      },
    },
    selectedAddress: {
      async handler(v) {
        // console.log(v);
        if (v === null) {
          return;
        }
        if (this.isAddressRetrieveLoading) {
          return;
        }
        // console.log(v, this.selectedAddress);
        if (this.selectedAddress.Next === "Find") {
          this.isAddressSearchLoading = true;
          try {
            let payload = {
              Key: this.settings.CanadaPostKey,
              SearchTerm: `${this.selectedAddress.Description}`,
              Country: this.selectedCountry.isOther
                ? this.otherCountry
                : this.selectedCountry.code,
              LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
            };
            if (this.selectedAddress.Id) {
              payload.LastId = this.selectedAddress.Id;
            }
            this.searchResults = (
              await this.canadaPostFind({
                payload: payload,
              })
            ).data.Items.map((o) => {
              o.Description = `${o.Text} ${o.Description}`;
              return o;
            });
            // console.log(this.searchResults);
            this.searchValue = null;
            this.onFocus();
          } catch (error) {
            console.log(error);
          } finally {
            this.isAddressSearchLoading = false;
          }
        } else if (this.selectedAddress.Next === "Retrieve") {
          this.isAddressRetrieveLoading = true;
          try {
            this.canadaPostAddress = (
              await this.canadaPostRetrieve({
                payload: {
                  Key: this.settings.CanadaPostKey,
                  Id: this.selectedAddress.Id,
                  LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
                },
              })
            ).data.Items;
            this.fetchedAddress = this.canadaPostAddress[0].Label;
            // console.log(this.fetchedAddress, this.canadaPostAddress);
            this.$emit("canada-post-address", this.createMemberAddress());
          } catch (error) {
            console.log(error);
          } finally {
            this.isAddressRetrieveLoading = false;
          }
        }
      },
    },
    selectedCountry: {
      handler(v) {
        if (v.textEn.toUpperCase() === "UNITED STATES") {
          this.otherCountry = "United States";
        }
      },
    },
  },
  mounted() {
    // console.log(this.user, this.identityUser);
    this.internalMember.EmailAddress = this.identityUser.Email;
    if (this.address) {
      this.fetchedAddress = this.$helpers.formatDrawbridgeAddress(this.address);
      // this.$emit("canada-post-address", this.address);
    }
    if (this.phone) {
      this.internalMember.HomePhone = this.phone;
    } else this.internalMember.HomePhone = null;
  },
  methods: {
    ...mapActions("data", ["canadaPostFind", "canadaPostRetrieve"]),
    provinceCode(provinceId) {
      const enumValue = this.enumModels.Province.filter(
        (o) => o.value === provinceId
      );
      return enumValue[0].textEn;
    },
    // createMemberAddressOld() {
    //   const address = this.canadaPostAddress[0];
    //   // console.log(address);
    //   const province = this.enumModels.Province.filter(
    //     (o) => o.code === address.ProvinceCode
    //   );
    //   // const ret = {
    //   //   StreetNumber: address.BuildingNumber,
    //   //   StreetName: address.Street,
    //   //   City: address.City,
    //   //   Province:
    //   //     province.length > 0 ? province[0].value : address.ProvinceCode,
    //   //   PostalCode: address.PostalCode,
    //   //   Country: this.selectedCountry.value,
    //   //   AptUnitSuite: address.SubBuilding,
    //   // };
    //   let ret;
    //   if (
    //     this.selectedCountry.isOther ||
    //     this.selectedCountry.textEn.toUpperCase() === "UNITED STATES"
    //   ) {
    //     ret = {
    //       StreetNumber: address.BuildingNumber,
    //       StreetName: address.Street,
    //       City: address.City,
    //       State:
    //         this.selectedCountry?.code === "USA" ? address.ProvinceName : null,
    //       OtherProvince: address.ProvinceName,
    //       OtherPostalCode: address.PostalCode,
    //       OtherCountry: this.otherCountry,
    //       SuiteNumber: address.SubBuilding,
    //       AptUnitSuite: address.SubBuilding,
    //     };
    //   } else {
    //     this.internalMember.Address = {
    //       StreetNumber: address.BuildingNumber,
    //       StreetName: address.Street,
    //       City: address.City,
    //       Province:
    //         province.length > 0
    //           ? this.provinceCode(province[0].value)
    //           : address.ProvinceCode,
    //       PostalCode: address.PostalCode,
    //       Country: this.selectedCountry.textEn,
    //       SuiteNumber: address.SubBuilding,
    //     };
    //   }
    //   // console.log(ret);
    //   return ret;
    // },
    createMemberAddress() {
      let address = this.canadaPostAddress[0];
      console.log("address to parse", address);
      const lines = address.Label.split(/\r?\n/);
      const postalCode = address.PostalCode ? address.PostalCode : "";

      if (
        this.selectedCountry.isOther ||
        this.selectedCountry?.textEn.toUpperCase() === "UNITED STATES"
      ) {
        const addressLine = lines.length > 0 ? lines[0] : null;
        const addressLine2 = lines.length > 1 ? lines[1] : null;
        const line2 = lines.length > 2 ? lines[2] : "";
        const province = address.ProvinceName;
        let addressLine3 = "";
        if (
          line2 &&
          line2.toUpperCase().includes(address?.CountryName?.toUpperCase())
        ) {
          addressLine3 = line2
            .toUpperCase()
            .replace(address?.CountryName?.toUpperCase(), "");
        } else {
          addressLine3 = line2;
        }

        if (
          !addressLine.includes(postalCode) &&
          !addressLine2.includes(postalCode) &&
          !addressLine3.includes(postalCode)
        ) {
          addressLine3 = addressLine3
            ? addressLine3 + " " + postalCode
            : postalCode;
        }

        if (
          !addressLine?.toUpperCase().includes(province?.toUpperCase()) &&
          !addressLine2?.toUpperCase().includes(province?.toUpperCase()) &&
          !addressLine3?.toUpperCase().includes(province?.toUpperCase())
        ) {
          addressLine3 = addressLine3
            ? addressLine3 + " " + province
            : province;
        }
        this.internalMember.Address = {
          Category:
            this.selectedCountry?.code === "USA"
              ? "Non-Civic US"
              : "Non-Civic International",
          StreetNumber: address.BuildingNumber,
          StreetName: address.Street,
          City: address.City,
          State:
            this.selectedCountry?.code === "USA" ? address.ProvinceName : null,
          Country: null,
          Province: null,
          OtherProvince: address.ProvinceName,
          OtherPostalCode: address.PostalCode,
          OtherCountry: this.otherCountry,
          SuiteNumber: address.SubBuilding,
          AptUnitSuite: address.SubBuilding,
          AddressLine: addressLine,
          AddressLine2: addressLine2,
          AddressLine3: addressLine3,
        };
      } else {
        const province = this.enumModels.Province.filter(
          (o) => o.code === address.ProvinceCode
        );
        // console.log("the province is", province);
        const { streetType, streetDirection, streetName } =
          this.getStreetTypeAndDirection(address);
        this.internalMember.Address = {
          Category: "Civic",
          StreetNumber: address.BuildingNumber,
          StreetName: address.Street,
          StreetNameOnly: streetName,
          StreetDirection: streetDirection,
          StreetType: streetType,
          City: address.City,
          Province:
            province.length > 0 ? province[0].value : address.ProvinceCode,
          PostalCode: address.PostalCode,
          Country: this.selectedCountry?.value,
          SuiteNumber: address.SubBuilding,
          AptUnitSuite: address.SubBuilding,
        };
      }
      return this.internalMember.Address;
    },
    getStreetTypeAndDirection(address) {
      const streets = this.$helpers.parseStreetByCountryAndLanguage(
        address,
        this.selectedCountry
      );
      const streetType = streets[0]?.streetType;
      const { streetName, streetDirection } = this.$helpers.cleanUpStreetName(
        address?.Street
      );
      console.log(
        "cleaned up streetName:",
        streetName,
        "streetType:",
        streetType,
        "streetDirection:",
        streetDirection
      );
      return { streetName, streetType, streetDirection };
    },
    unmask(v, mask) {
      // console.log(v, mask);
      if (v) {
        return masker(v, mask, false, tokens);
      }
      return v;
    },
    onFocus() {
      this.$refs.addressList.isMenuActive = true;
    },
    formatPhone(v) {
      return masker(v, this.telephoneMask, true, tokens);
    },
    checkVisible(field) {
      const result = this.formFields?.find((o) => o.Text === field);
      return result ? result.OnCreate === "Edit" : false;
    },
    checkRequired(field) {
      const result = this.formFields?.find((o) => o.Text === field);
      return result ? !result.IsOptional : false;
    },
  },
};
</script>
<style scoped>
.camera-btn {
  right: 25px;
  top: 25px;
  position: relative;
}
.avatar {
  border: 3px white solid;
}
/* .v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.87) !important;
} */
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.v-tooltip__content {
  max-width: 150px !important;
}
</style>
