var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"beneficiaries-table",attrs:{"headers":_vm.allowedHeaders,"items":_vm.internalMember.Dependants,"item-class":_vm.rowColorClass,"hide-default-footer":"","mobile-breakpoint":"960"},scopedSlots:_vm._u([{key:`item.FirstName`,fn:function({ item }){return [_c('td',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" "+_vm._s(item.MiddleName ? _vm.$helpers.capitalize( item.FirstName + " " + item.MiddleName + " " + item.LastName ) : _vm.$helpers.capitalize(item.FirstName + " " + item.LastName))+" ")])]}},{key:`item.DateOfBirth`,fn:function({ item }){return [_c('td',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" "+_vm._s(_vm.$helpers.formatDate(item.DateOfBirth))+" ")])]}},{key:`item.Gender`,fn:function({ item }){return [_c('td',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" "+_vm._s(_vm.$helpers.formatEnumModel( item.Gender, "Gender", _vm.$vuetify.lang.current ))+" ")])]}},{key:`item.Relationship`,fn:function({ item }){return [(item.Relationship?.Id)?_c('td',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" "+_vm._s(_vm.$helpers.formatDrawbridgeEnumModel( item?.Relationship?.Id, "Relationship", _vm.$vuetify.lang.current ))+" ")]):_c('td',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" "+_vm._s(_vm.$helpers.formatDrawbridgeEnumModel( item?.Relationship, "Relationship", _vm.$vuetify.lang.current ))+" ")])]}},{key:`item.Eligible`,fn:function({ item }){return [_c('td',{staticClass:"px-0 text-center"},[(item.Eligible && !item.IsRemoved)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(
              item.Eligible !== false &&
              item.Eligible == true &&
              !item.IsRemoved
            )?_c('div',{staticClass:"primary--text"}):_vm._e(),(item.Eligible === false && !item.IsRemoved)?_c('div',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t("globals.no"))+" ")]):_vm._e(),(item.IsRemoved)?_c('div',{staticClass:"secondary--text text--lighten-4"},[_vm._v(" "+_vm._s(_vm.$t("globals.removed"))+" ")]):_vm._e()],1)]}},{key:`item.Qualification`,fn:function({ item }){return [_c('td',{staticClass:"px-0 text-center"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(item.Qualification?.ProofOfCommonLawStatus)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.previewProof(
                      item.Qualification.ProofOfCommonLawStatus,
                      _vm.$t('components.dependants_form.proof_of_common_law')
                    );
                    _vm.loadingCommonLawProof = true;
                    _vm.currentItemId = item.Id;}}},[(!_vm.loadingCommonLawProof || _vm.currentItemId !== item.Id)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"secondary lighten-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-heart ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","size":"24"}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(`components.dependants_form.proof_of_common_law`)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(item.Qualification?.ProofOfLifeEvent)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.previewProof(
                      item.Qualification.ProofOfLifeEvent,
                      _vm.$t('components.dependants_form.proof_of_life_event')
                    );
                    _vm.loadingLifeEventProof = true;
                    _vm.currentItemId = item.Id;}}},[(!_vm.loadingLifeEventProof || _vm.currentItemId !== item.Id)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"secondary lighten-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-mother-heart ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","size":"24"}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(`components.dependants_form.proof_of_life_event`)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(item.Qualification?.ProofOfSchooling)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.previewProof(
                      item.Qualification.ProofOfSchooling,
                      _vm.$t('components.dependants_form.proof_of_schooling')
                    );
                    _vm.loadingSchoolingProof = true;
                    _vm.currentItemId = item.Id;}}},[(!_vm.loadingSchoolingProof || _vm.currentItemId !== item.Id)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"secondary lighten-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-school ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","size":"24"}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(`components.dependants_form.proof_of_schooling`)))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(item.Qualification?.ProofOfDisability)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.previewProof(
                      item.Qualification.ProofOfDisability,
                      _vm.$t('components.dependants_form.proof_of_disability')
                    );
                    _vm.loadingDisabilityProof = true;
                    _vm.currentItemId = item.Id;}}},[(
                      !_vm.loadingDisabilityProof || _vm.currentItemId !== item.Id
                    )?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"secondary lighten-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-wheelchair ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","size":"24"}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(`components.dependants_form.proof_of_disability`)))])])],1)],1)]}},{key:`item.Actions`,fn:function({ item }){return [_c('td',{staticClass:"text-subtitle-1 font-weight-bold text-right px-0",staticStyle:{"width":"80px"}},[_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":item.IsRemoved},on:{"click":function($event){return _vm.editDependant(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":item.IsRemoved},on:{"click":function($event){return _vm.confirmDeleteDependant(item)}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)],1)]}},{key:"footer",fn:function(){return [_c('v-divider'),(_vm.canEditDependants)?_c('v-row',{staticClass:"ma-2",attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"text":""},on:{"click":_vm.add}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t("globals.add"))+" ")],1),_c('v-spacer'),(_vm.isRegistrationCompletion)?_c('v-btn',{attrs:{"disabled":_vm.saving,"text":"","color":"secondary"},on:{"click":_vm.resetAllDependants}},[_c('v-icon',[_vm._v("mdi-reload")]),_vm._v(" "+_vm._s(_vm.$t("globals.reset_all"))+" ")],1):_vm._e()],1):_vm._e(),(_vm.dependantDefinition)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-4 text-left text-caption",domProps:{"innerHTML":_vm._s(
              _vm.$vuetify.lang.current === 'fr'
                ? _vm.dependantDefinition.FR
                : _vm.dependantDefinition.EN
            )}})],1):_vm._e()]},proxy:true}],null,true)}),_c('v-overlay',{attrs:{"value":_vm.saving && !_vm.savingImage,"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"max-width":"1000","persistent":""},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('dependant-form',{attrs:{"minEffectiveDate":_vm.internalMember?.PlanEntryDate,"dependant":_vm.selectedDependant,"dependantSaved":_vm.dependantSaved,"dependants":_vm.internalMember.Dependants,"isRegistrationCompletion":_vm.isRegistrationCompletion,"saving":_vm.savingDependant,"title":_vm.title,"titleIcon":_vm.titleIcon},on:{"cancel-dialog":function($event){_vm.editDialog = false;
          _vm.selectedDependant = {};
          _vm.editDialog = false;},"save-dependant":_vm.saveDependant}})],1),_c('v-dialog',{attrs:{"max-width":"800","persistent":""},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('delete-dependant-dialog',{attrs:{"minEffectiveDate":_vm.internalMember?.CoverageEffectiveDate,"dependant":_vm.selectedDependant,"deleteAll":_vm.deleteAll,"dependantSaved":_vm.dependantSaved,"dialogMessage":_vm.dialogMessage,"isRegistrationCompletion":_vm.isRegistrationCompletion,"saving":_vm.savingDependant,"title":_vm.title},on:{"delete-dependant":_vm.deleteDependantHandler,"delete-all":_vm.deleteAllDependantsHandler,"cancel-dialog":function($event){_vm.deleteDialog = false;
          _vm.deleteAll = false;
          _vm.editDialog = false;}}})],1)],1),(_vm.preview)?_c('file-preview-base64',{attrs:{"fullScreen":false,"view":_vm.preview,"fileItems":_vm.fileItems},on:{"close":_vm.closePreview}}):_vm._e(),_c('error-handler',{attrs:{"absolute":"","error":_vm.error}}),_c('v-snackbar',{attrs:{"absolute":"","color":"success","timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" "+_vm._s(_vm.$t("globals.close"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }