<template>
  <div>
    <v-card class="pa-0" flat width="1200">
      <v-card-title class="justify-center primary--text pb-0 pb-sm-5">
        {{ $t("components.registration_completion.page_title") }}
      </v-card-title>
      <!-- <v-card-text class="text-subtitle-1 py-0">{{
          $t("components.registration_completion.page_subtitle")
        }}</v-card-text> -->
      <v-card-text class="pa-0" v-if="member">
        <v-stepper v-model="step" vertical flat>
          <v-stepper-step
            step="1"
            :complete="step1Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion.step1_title") }}
            <small class="mt-2 black--text">
              {{ $t("components.registration_completion_sba.step1_subtitle") }}
            </small>
          </v-stepper-step>

          <v-stepper-content
            step="1"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <registration-completion-form-sba
              :formFields="formFields"
              :member="member"
              :savingImage="savingImage"
              :dob="dob"
              @dob="dob = $event"
            />
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelDialog = true">
                {{ $t("globals.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="verify"
                class="mx-2"
                :disabled="!member.FirstName"
              >
                <span v-if="isDateOfBirthDisabled">
                  {{
                    $t("components.registration_completion.verify_and_continue")
                  }}
                </span>
                <span v-else>
                  {{
                    $t("components.registration_completion.update_and_continue")
                  }}
                </span>
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            step="2"
            :complete="step2Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion.step2_title") }}
            <small class="mt-2 black--text">
              {{ $t("components.registration_completion_sba.step2_subtitle") }}
            </small>
          </v-stepper-step>

          <v-stepper-content
            step="2"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <contacts-registration-form-sba
              :formFields="formFields"
              :address="member.Address"
              :phone="member.HomePhone"
              @update-valid="updateValid"
              @update-member="
                contactInfo = $event;
                drawbridgeContactInfoUpdated = true;
              "
              :saving="saving"
              @canada-post-address="updateCanadaPostAddress"
            />
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelDialog = true">
                {{ $t("globals.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="updateAddress"
                class="mx-2"
                :disabled="!valid.contactsValidation"
                :loading="saving"
              >
                {{ $t("globals.continue") }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            step="3"
            :complete="step3Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion_sba.step3_title") }}
            <small class="mt-2 black--text">
              {{ $t("components.registration_completion_sba.step3_subtitle") }}
              <span
                class="custom-underline primary--text"
                @click="chequeExampleDialog = true"
              >
                {{ $t("globals.here") }}.
              </span>
            </small>
          </v-stepper-step>

          <v-stepper-content
            step="3"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <direct-deposit-completion-form
              :formFields="formFields"
              :memberBankAccount="member.BankAccount"
              :saving="saving"
              @update-valid="valid.bankAccount = $event"
              @update-bank-account="bankAccount = $event"
            />
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelDialog = true">
                {{ $t("globals.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="updateDirectDeposit"
                class="mx-2"
                :disabled="!valid.bankAccount"
                :loading="saving"
              >
                {{ $t("globals.continue") }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            v-if="canEditDependants"
            step="4"
            :complete="step === 5"
            class="pl-2 pl-sm-6 pb-0"
          >
            {{ $t("components.registration_completion.step5_dependats_title") }}
          </v-stepper-step>

          <v-stepper-content
            v-if="canEditDependants"
            step="4"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <dependants-expansion-panel
              :member="member"
              :saving="saving"
              :member-saved="dependantsUpdated"
              :isRegistrationCompletion="true"
              @save-dependants="updateInternalDependants"
            />
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelDialog = true">
                {{ $t("globals.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="updateDependants"
                class="mx-2"
                :loading="saving"
              >
                {{ $t("globals.continue") }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            :step="canEditDependants ? 5 : 4"
            :complete="step4Completed"
            class="pl-2 pl-sm-6"
          >
            {{ $t("components.registration_completion.step4_title") }}
            <!-- <small class="mt-2 black--text">
              {{ $t("components.registration_completion.step4_subtitle") }}
            </small> -->
          </v-stepper-step>

          <v-stepper-content
            :step="canEditDependants ? 5 : 4"
            :complete="step4Completed"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <profile-picture-form
              :member="member"
              @update-valid="valid.contactsValidation = $event"
              @save-image="newAvatarImage = $event"
              :saving="saving"
            />
            <v-row no-gutters justify="end">
              <v-btn text @click="cancelDialog = true">
                {{ $t("globals.cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="updateProfilePicture"
                class="mx-2"
                :disabled="!valid.contactsValidation"
                :loading="saving"
              >
                {{ $t("globals.continue") }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            :step="canEditDependants ? 6 : 5"
            :complete="step === 5"
            class="pl-2 pl-sm-6 pb-0"
          >
            {{ $t("components.registration_completion.step5_title") }}
          </v-stepper-step>

          <v-stepper-content
            :step="canEditDependants ? 6 : 5"
            class="text-left px-0 px-sm-4 mr-0 ml-4 ml-sm-9 pt-0 pt-sm-4"
          >
            <div v-if="userProfile?.MemberPlans?.length < 2">
              <v-card-text class="text-subtitle-1 mb-2">
                {{
                  $t("components.member_registration_completed.page_subtitle")
                }}
                <br />
              </v-card-text>
              <v-row no-gutters justify="end">
                <v-btn
                  color="primary"
                  @click="goToDashboard()"
                  class="mx-2"
                  :disabled="!valid.contactsValidation"
                  :loading="saving"
                >
                  {{ $t("globals.close") }}
                </v-btn>
              </v-row>
            </div>
            <!-- Update other plans -->
            <div v-else>
              <v-card-text class="text-subtitle-1">
                {{
                  $t(
                    "components.member_registration_completed.update_all_plans"
                  )
                }}
                <br />
              </v-card-text>
              <v-card-actions class="pa-4 pt-0 pb-1 justify-end">
                <v-btn @click="doNotUpdateOnAllPlans">{{
                  $t("globals.no")
                }}</v-btn>
                <v-btn
                  :loading="updatingPlans"
                  color="primary"
                  class="mr-3"
                  @click="updateMemberPlans"
                  >{{ $t("globals.yes") }}</v-btn
                >
              </v-card-actions>
            </div>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <v-container fluid grid-list-md v-else>
        <v-layout row justify-center>
          <v-flex xs12 sm6 md4 lg3>
            <v-progress-circular
              color="info"
              size="40"
              indeterminate
            ></v-progress-circular>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>

    <!-- Confirm cancellation dialog -->
    <v-dialog max-width="800" v-model="cancelDialog" persistent>
      <v-card>
        <v-card-title class="primary white--text mb-6">
          <v-icon dark class="mr-2">mdi-alert-outline</v-icon>
          {{ $t("components.member_registration.cancel_dialog.title") }}
        </v-card-title>
        <v-card-text class="px-5 mb-5 text-subtitle-1">
          <v-row>
            <v-col>
              {{ $t("components.member_registration.cancel_dialog.message") }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-4 pt-0 justify-end">
          <v-btn @click="cancel">{{ $t("globals.cancel") }}</v-btn>
          <v-btn color="primary" class="mr-3" @click="cancelRegistration">
            {{
              $t(
                "components.member_registration.cancel_dialog.exit_registration"
              )
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="chequeExampleDialog" persistent max-width="800">
      <v-card max-width="800">
        <v-card-title class="primary white--text text-subtitle-1 mb-6">
          <v-icon dark class="mr-2">mdi-help-circle-outline</v-icon>
          {{
            $t(
              "components.direct_deposit_expansion_panel.cheque_example_dialog.title"
            )
          }}
        </v-card-title>
        <v-card-text>
          <v-img
            v-if="$vuetify.lang.current === 'fr'"
            :src="require('@/assets/ChequeFR.png')"
          ></v-img>
          <v-img v-else :src="require('@/assets/ChequeEN.png')"></v-img>
        </v-card-text>
        <v-card-actions class="pa-5 justify-end pt-0">
          <v-btn color="primary" @click="chequeExampleDialog = false">
            {{ $t("globals.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-model="confirmBankAccountDialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="primary white--text mb-6">
          <v-icon dark class="mr-2">warning</v-icon>
          {{
            $t(
              "components.direct_deposit_expansion_panel.confirm_bank_account_dialog_title"
            )
          }}
        </v-card-title>
        <v-card-actions>
          <v-btn color text @click="confirmBankAccountDialog = false">
            {{ $t("globals.close") }}
          </v-btn>
          <v-spacer />
          <v-btn color="primary" @click="continueBankAccount">
            {{ $t("globals.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="success" :timeout="timeout">
      {{ successMessage }}
      <v-btn dark text @click="snackbar = false">
        {{ $t("globals.close") }}
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      :color="vuetifyTheme.Light.Error"
      :timeout="-1"
    >
      {{ customErrorMessage
      }}<span
        class="custom-underline secondary--text"
        @click="contactDialog = true"
        >{{ $t("globals.here") }}</span
      >
      <v-btn dark text @click="errorSnackbar = false">
        {{ $t("globals.close") }}
      </v-btn>
    </v-snackbar>
    <error-handler :error="error"></error-handler>
  </div>
</template>

<script>
import ErrorHandler from "@/components/ErrorHandler.vue";
import RegistrationCompletionFormSba from "@/components/RegistrationCompletion/RegistrationCompletionFormSba.vue";
import ProfilePictureForm from "@/components/RegistrationCompletion/ProfilePictureForm.vue";
import ContactsRegistrationFormSba from "@/components/RegistrationCompletion/ContactsRegistrationFormSba.vue";
import { mapGetters, mapActions } from "vuex";
import DirectDepositCompletionForm from "@/components/RegistrationCompletion/DirectDepositCompletionForm.vue";
import DependantsExpansionPanel from "@/components/MemberInformation/items/panels/DependantsExpansionPanel.vue";
import { tokens } from "vue-the-mask";
import masker from "vue-the-mask/src/masker";
export default {
  components: {
    ErrorHandler,
    RegistrationCompletionFormSba,
    ProfilePictureForm,
    ContactsRegistrationFormSba,
    DirectDepositCompletionForm,
    DependantsExpansionPanel,
  },
  props: {
    member: Object,
  },
  data() {
    return {
      valid: {
        memberValidation: false,
        bankAccount: false,
        contactsValidation: false,
      },
      telephoneMask: "(###) ### - ####",
      savingImage: false,
      cancelDialog: false,
      chequeExampleDialog: false,
      newAvatarImage: null,
      error: null,
      saving: false,
      snackbar: false,
      step: 1,
      successMessage: null,
      timeout: -1,
      step1Completed: false,
      step2Completed: false,
      step3Completed: false,
      step4Completed: false,
      step5Completed: false,
      bankAccount: {},
      contactInfo: {},
      dependantsUpdateData: null,
      dependantsUpdated: false,
      address: null,
      dob: undefined,
      confirmBankAccountDialog: false,
      drawbridgeContactInfoUpdated: false,
      drawbridgeAddressUpdated: false,
      bankAccountUpdated: false,
      updateMemberAddress: false,
      customErrorMessage: null,
      errorSnackbar: false,
      updatingPlans: false,
      lastSavedAddress: null,
      formFields: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", [
      "planAdministrator",
      "userProfile",
      "enumModels",
      "identityUser",
      "optionalMemberFields",
      "canEditDependants",
      "adminEmailRecipients",
      "vuetifyTheme",
    ]),
    bankAccountIsEmpty() {
      return (
        !this.bankAccount.AccountNumber &&
        !this.bankAccount.InstitutionNumber &&
        !this.bankAccount.TransitNumber
      );
    },
    addressIsEmpty() {
      let result = true;
      if (this.address === null || this.address === undefined) {
        return true;
      }
      Object.values(this.address).forEach((v) => {
        if (v) {
          result = false;
        }
      });
      return result;
    },
    isDateOfBirthDisabled() {
      return (
        this.optionalMemberFields.filter((o) => o === "DateOfBirth").length ===
        0
      );
    },
  },
  watch: {
    member: {
      handler(v) {
        if (v) {
          this.dob = v.Dob.split("T")[0];
          this.address = v?.Address;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.getFormFields();
  },
  methods: {
    ...mapActions("auth", ["logout", "setUser", "setIsLoggedIn"]),
    ...mapActions("users", ["updateUser"]),
    ...mapActions("common", ["loadUserProfileData"]),
    ...mapActions("data", [
      "checkValidMember",
      "checkValidEmail",
      "verifyMember",
      "validateBankAccount",
      "updateMemberInfoOnAllPlans",
      "getMemberFields",
    ]),
    ...mapActions("common", ["clearUserProfileData"]),
    async getFormFields() {
      try {
        this.loading = true;
        const fields = (
          await this.getMemberFields({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.drawbridgeMemberId,
          })
        )?.data;
        // if (fields) this.formFields = fields.filter((o) => o.Visible);
        if (fields) this.formFields = fields;
        // console.log("all unfiltered fields", fields);
        // console.log("filtered visible Only", this.formFields);
      } catch (error) {
        this.error = error;
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async verify() {
      this.step1Completed = true;
      this.step = await this.checkStepsCompletion();
    },
    updateValid(event) {
      // console.log("update valid", event);
      this.valid.contactsValidation = event;
    },
    async updateAddress() {
      this.step2Completed = true;
      this.step = await this.checkStepsCompletion();
    },
    updateCanadaPostAddress(address) {
      console.log("canada post address update", address);
      this.address = address;
      this.lastSavedAddress = address;
      // this.valid.contactsValidation = true;
      this.drawbridgeContactInfoUpdated = true;
      this.drawbridgeAddressUpdated = true;
    },
    async updateDirectDeposit() {
      if (!this.bankAccountIsEmpty) {
        // if (this.address === "ddd") {
        try {
          this.validating = true;
          const valid = (
            await this.validateBankAccount({
              payload: {
                InstitutionNumber: this.bankAccount.InstitutionNumber,
                TransitNumber: this.bankAccount.TransitNumber,
              },
            })
          ).data;
          this.bankAccountUpdated = true;
          if (!valid) {
            this.confirmBankAccountDialog = true;
          } else {
            this.step3Completed = true;
            this.step = await this.checkStepsCompletion();
          }
        } catch (error) {
          this.error = error;
        } finally {
          this.validating = false;
        }
      } else {
        this.step3Completed = true;
        this.step = await this.checkStepsCompletion();
      }
    },

    unmask(v, mask) {
      if (v) {
        return masker(v, mask, false, tokens);
      }
      return v;
    },

    async continueBankAccount() {
      this.confirmBankAccountDialog = false;
      this.step3Completed = true;
      this.step = await this.checkStepsCompletion();
    },

    checkAddress() {
      if (!this.address.Category) {
        if (this.address.Country) {
          this.address.Category = "Civic";
          const country = this.enumModels?.Country?.find(
            (c) => c.textEn?.toUpperCase() === "CANADA"
          );
          this.address.CountryId = country?.value;
          this.address.CountryCode = country?.code;
          this.address.CountryName = country?.textEn;
          this.address.Country = country?.value;
        } else if (
          this.address.OtherCountry?.toUpperCase() === "UNITED STATES"
        ) {
          this.address.Category = "Non-Civic US";
        } else this.address.Category = "Non-Civic International";
      }
      if (this.lastSavedAddress) this.address = this.lastSavedAddress;
      if (this.address.Category === "Civic") {
        let province = this.enumModels?.Province?.find(
          (p) => p.value?.toUpperCase() === this.address.Province?.toUpperCase()
        );
        if (!province) {
          province = this.enumModels?.Province?.find(
            (p) =>
              p.value?.toUpperCase() ===
                this.address.ProvinceId?.toUpperCase() ||
              p.code?.toUpperCase() === this.address.ProvinceCode?.toUpperCase()
          );
        }
        this.address.Province = province?.value;
        this.address.ProvinceCode = province?.code;
        this.address.ProvinceId = province?.value;
        this.address.ProvinceName = province?.textEn;
        // this.address.Country = this.enumModels?.Country?.find(
        //   (c) => c.value?.toUpperCase() === this.address.Country?.toUpperCase()
        // )?.textEn;
      } else {
        this.address.Province = null;
        this.address.Country = null;
      }
    },

    async updateMemberPlans() {
      this.updatingPlans = true;
      this.errorSnackbar = false;
      this.snackbar = false;
      // console.log("update all plans address", JSON.stringify(this.address));
      this.successMessage = this.$t(
        "components.registration_completion.update_plans.success"
      );
      this.checkAddress();
      // console.log("contry is", this.address);
      try {
        await this.updateMemberInfoOnAllPlans({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: {
            memberProfile: {
              Address: this.address,
              BankAccount: this.bankAccount,
              EmailAddress: this.member.EmailAddress,
              SecondaryEmail: this.member.SecondaryEmail,
              HomePhone: this.unmask(
                this.contactInfo.HomePhone,
                this.telephoneMask
              ),
              MobilePhone: this.unmask(
                this.contactInfo.MobilePhone,
                this.telephoneMask
              ),
              Communication: this.member.Communication,
              Language: this.member.Language,
              PreferredPhone: this.contactInfo.PreferredPhone,
            },
            memberPlans: this.member.MemberPlans,
            updateFlags: {
              bankAccountUpdated: this.bankAccountUpdated,
              drawbridgeContactInfoUpdated: this.drawbridgeContactInfoUpdated,
              drawbridgeAddressUpdated: this.drawbridgeAddressUpdated,
            },
          },
        });
        await this.loadUserProfileData({
          planId: this.user.plan,
          userId: this.user.sub,
        });
        this.snackbar = true;
        this.link = null;
        this.linkText = null;
        this.goToDashboard();
        this.drawbridgeContactInfoUpdated = false;
        this.drawbridgeAddressUpdated = false;
        this.bankAccountUpdated = false;
      } catch (error) {
        console.log(error);
        this.customErrorMessage = this.$t(
          "components.registration_completion.update_plans.error"
        );
        this.step = 5;
        this.errorSnackbar = true;
        await this.updateUser({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: {
            ...this.user,
            isRegistrationCompleted: false,
          },
        });
        await this.loadUserProfileData({
          planId: this.user.plan,
          userId: this.user.sub,
        });
      } finally {
        this.updatingPlans = false;
      }
    },

    async doNotUpdateOnAllPlans() {
      await this.updateUser({
        planIdentityId: this.user.plan,
        memberIdentityId: this.user.sub,
        payload: {
          ...this.user,
          isRegistrationCompleted: true,
        },
      });
      this.goToDashboard();
    },

    cancel() {
      this.cancelDialog = false;
    },

    async updateProfilePicture() {
      if (this.canEditDependants) {
        this.step5Completed = true;
      } else {
        this.step4Completed = true;
      }
      this.step = await this.checkStepsCompletion();
    },

    async updateDependants() {
      this.step4Completed = true;
      this.step = await this.checkStepsCompletion();
    },

    async updateInternalDependants(event) {
      console.log("updateInternalDependants", event);
      this.dependantsUpdateData = event;
      this.$emit("update-dependants", event);
      this.dependantsUpdated = true;
      setTimeout(() => {
        this.dependantsUpdated = false;
      }, 500);
    },

    async checkStepsCompletion() {
      if (!this.step1Completed) {
        this.error = {
          customMessage: this.$t(
            "components.registration_completion.update_contacts.error_other_steps"
          ),
        };
        return 1;
      }
      if (!this.step2Completed) {
        if (this.step !== 1) {
          this.error = {
            customMessage: this.$t(
              "components.registration_completion.update_contacts.error_other_steps"
            ),
          };
        }
        return 2;
      }
      if (!this.step3Completed) {
        if (this.step !== 2) {
          this.error = {
            customMessage: this.$t(
              "components.registration_completion.update_contacts.error_other_steps"
            ),
          };
        }
        return 3;
      }
      if (!this.step4Completed) {
        return 4;
      }

      if (this.canEditDependants && !this.step5Completed) {
        return 5;
      }

      try {
        this.saving = true;
        await this.saveUser(true);
        return this.canEditDependants ? 6 : 5;
      } catch (error) {
        console.log(error);
        this.error = {
          customMessage: this.$t(
            "components.registration_completion.error.unable_to_complete"
          ),
        };
        // this.error = error;
        return this.canEditDependants ? 5 : 4;
      } finally {
        this.saving = false;
      }
    },

    cancelRegistration() {
      this.clearUserProfileData();
      this.logout();
      this.setUser(undefined);
      this.setIsLoggedIn(false);
      this.$router.push({ name: "login" });
    },

    goToDashboard() {
      this.$emit("close-dialog");
      this.$router.push("/");
    },

    async saveUser(isRegistrationCompleted) {
      this.successMessage = this.$t(
        "components.registration_completion.save_member.success"
      );
      this.contactInfo.EmailAddress = this.member.EmailAddress;
      this.contactInfo.HomePhone = this.unmask(
        this.contactInfo.HomePhone,
        this.telephoneMask
      );
      this.contactInfo.MobilePhone = this.unmask(
        this.contactInfo.MobilePhone,
        this.telephoneMask
      );
      // this.checkAddress();
      this.contactInfo.Address =
        this.addressIsEmpty && !this.drawbridgeAddressUpdated
          ? null
          : this.lastSavedAddress ?? this.address;
      const adminRecipients =
        this.$vuetify.lang.current === "fr"
          ? this.adminEmailRecipients.Fr
          : this.adminEmailRecipients.En;
      await this.verifyMember({
        planIdentityId: this.user.plan,
        memberIdentityId: this.user.drawbridgeMemberId,
        payload: {
          ContactInfo: this.contactInfo,
          BankAccount: this.bankAccountIsEmpty ? null : this.bankAccount,
          DateOfBirth: this.dob,
          Dependants: this.canEditDependants ? this.dependantsUpdateData : null,
          Recipients: adminRecipients,
          Language: this.$vuetify.lang.current,
        },
      });

      await this.updateUser({
        planIdentityId: this.user.plan,
        memberIdentityId: this.user.sub,
        payload: {
          ...this.user,
          image: this.newAvatarImage,
          isRegistrationCompleted: isRegistrationCompleted,
        },
      });
      this.snackbar = true;
      this.loadUserProfileData({
        planId: this.user.plan,
        userId: this.user.sub,
      });
    },
  },
};
</script>
