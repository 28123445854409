var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-4"},[(!_vm.fetchedAddress)?_c('v-form',{model:{value:(_vm.valid.address),callback:function ($$v) {_vm.$set(_vm.valid, "address", $$v)},expression:"valid.address"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('components.address_expansion_panel.country'),"items":_vm.enumModels.Country,"item-text":_vm.$vuetify.lang.current === 'en' ? 'textEn' : 'textFr',"item-value":"value","rules":[_vm.$rules.required],"return-object":"","clearable":""},on:{"change":function($event){_vm.otherCountry = null}},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),(_vm.selectedCountry)?[(_vm.selectedCountry.isOther)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{staticClass:"required errors-msg",attrs:{"outlined":"","clearable":"","items":_vm.countries,"label":_vm.$t('components.address_expansion_panel.other_country'),"rules":[_vm.$rules.required],"item-text":"text","item-value":"value"},on:{"change":function($event){_vm.selectedAddress = null}},model:{value:(_vm.otherCountry),callback:function ($$v) {_vm.otherCountry=$$v},expression:"otherCountry"}})],1):_vm._e(),(
            (_vm.selectedCountry.isOther && _vm.otherCountry !== null) ||
            !_vm.selectedCountry.isOther
          )?_c('v-col',{staticClass:"py-0 text--secondary pb-2",attrs:{"cols":"12","md":_vm.selectedCountry?.code === 'Other' ? 12 : 6}},[_c('v-autocomplete',{ref:"addressList",attrs:{"label":_vm.$t(
                'components.address_expansion_panel.start_typing_your_address'
              ),"items":_vm.searchResults,"return-object":"","loading":_vm.isAddressSearchLoading,"search-input":_vm.searchValue,"item-text":"Description","item-value":"Id","clearable":"","append-outer-icon":"mdi-magnify"},on:{"update:searchInput":function($event){_vm.searchValue=$event},"update:search-input":function($event){_vm.searchValue=$event}},model:{value:(_vm.selectedAddress),callback:function ($$v) {_vm.selectedAddress=$$v},expression:"selectedAddress"}})],1):_vm._e()]:_vm._e()],2)],1):(_vm.fetchedAddress)?_c('v-row',[_c('v-col',{staticClass:"py-0",staticStyle:{"margin-bottom":"20px"},attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0 font-weight-bold text--secondary",attrs:{"cols":"12","md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("components.address_expansion_panel.address"))+" ")])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0 text--secondary",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.fetchedAddress)+" ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0 pb-2",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.oldFetchedAddress = _vm.fetchedAddress;
              _vm.fetchedAddress = undefined;
              _vm.selectedAddress = null;
              _vm.searchResults = [];}}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.fetchedAddress === "" ? _vm.$t("components.address_expansion_panel.add_address") : _vm.$t("components.address_expansion_panel.change_address"))+" ")])])],1)],1)],1)],1):_vm._e(),(_vm.internalMember)?_c('v-form',{staticClass:"mt-2",model:{value:(_vm.valid.contactInfo),callback:function ($$v) {_vm.$set(_vm.valid, "contactInfo", $$v)},expression:"valid.contactInfo"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('no-autocomplete-textfield',{staticClass:"required highlight",attrs:{"disabled":"","outlined":"","label":_vm.$t(
              'components.registration_completion.contacts_form.email_readonly'
            ),"rules":[_vm.$rules.required, _vm.$rules.email]},model:{value:(_vm.internalMember.EmailAddress),callback:function ($$v) {_vm.$set(_vm.internalMember, "EmailAddress", $$v)},expression:"internalMember.EmailAddress"}})],1),(_vm.checkVisible('Phone Number'))?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('no-autocomplete-textfield',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.telephoneMask),expression:"telephoneMask"}],class:_vm.checkRequired('Phone Number') ? 'required' : '',attrs:{"clearable":"","outlined":"","label":_vm.$t('components.contact_information_expansion_panel.telephone'),"rules":_vm.checkRequired('Phone Number')
              ? [_vm.rules.phone, _vm.$rules.required]
              : [_vm.rules.phone]},model:{value:(_vm.internalMember.HomePhone),callback:function ($$v) {_vm.$set(_vm.internalMember, "HomePhone", $$v)},expression:"internalMember.HomePhone"}})],1):_vm._e(),(_vm.hasPensionPlan)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"required",attrs:{"outlined":"","label":_vm.$t('components.contact_information_expansion_panel.phone_type'),"rules":[_vm.$rules.required],"items":_vm.phoneTypes},model:{value:(_vm.internalMember.PreferredPhone),callback:function ($$v) {_vm.$set(_vm.internalMember, "PreferredPhone", $$v)},expression:"internalMember.PreferredPhone"}})],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }