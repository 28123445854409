<template>
  <v-card :loading="saving ? 'secondary' : false">
    <v-card-title class="primary white--text mb-6">
      <v-icon dark class="mr-2">mdi-account-alert-outline</v-icon>
      {{ title }}
    </v-card-title>
    <v-card-title class="px-5 mb-5">
      <v-card-text>
        <v-row>
          <v-col class="text-h6">{{ dialogMessage }}</v-col>
        </v-row>
        <v-row justify="center" v-show="!isRegistrationCompletion">
          <v-col cols="12" md="6" class="pb-0">
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  v-mask="'####-##-##'"
                  outlined
                  v-model="effectiveDate"
                  :label="$t('globals.effective_date')"
                  append-icon="mdi-calendar"
                  @click:append="dateMenu = true"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[$rules.required, $rules.validDate]"
                  class="required"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="effectiveDate"
                :min="minEffectiveDate"
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card-title>
    <v-card-actions class="pa-4 pt-0 justify-end">
      <v-btn @click="cancel">{{ $t("globals.cancel") }}</v-btn>
      <v-btn
        v-show="!deleteAll && !dependant.IsNew"
        :disabled="disabled"
        :loading="saving"
        color="primary"
        @click="deleteDependant"
      >
        {{ $t("globals.terminate") }}
      </v-btn>
      <v-btn
        v-show="!deleteAll && dependant.IsNew"
        :disabled="disabled"
        :loading="saving"
        color="primary"
        @click="deleteDependant"
      >
        {{ $t("globals.remove") }}
      </v-btn>
      <v-btn
        v-show="deleteAll"
        :disabled="disabled"
        :loading="saving"
        color="primary"
        class="mr-3"
        @click="deleteAllDependants"
      >
        {{ $t("globals.remove_all") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    dependant: Object,
    saving: Boolean,
    minEffectiveDate: String,
    isRegistrationCompletion: Boolean,
    title: String,
    dialogMessage: String,
    dependantSaved: Boolean,
    deleteAll: Boolean,
  },
  data() {
    return {
      dateMenu: false,
      effectiveDate: "",
    };
  },
  computed: {
    disabled() {
      return this.isRegistrationCompletion
        ? false
        : !this.effectiveDate || this.saving;
    },
  },
  watch: {
    dependantSaved(v) {
      if (v) {
        this.cancel();
      }
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel-dialog");
      this.effectiveDate = null;
    },
    deleteDependant() {
      const dependantToDelete = {
        ...this.dependant,
        EffectiveDate: this.effectiveDate,
        IsRemoved: true,
      };
      this.$emit("delete-dependant", dependantToDelete);
    },
    deleteAllDependants() {
      this.$emit("delete-all", this.effectiveDate);
    },
  },
};
</script>
