<template>
  <v-dialog
    v-model="internalModel"
    :width="width"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card :width="width">
      <v-card-title
        class="white--text pa-2 pl-4 text-h6 font-weight-medium"
        :class="titleColor || 'primary'"
      >
        <v-icon dark class="pr-2">{{ titleIcon }}</v-icon>
        {{ title }}
      </v-card-title>
      <v-card-text v-if="html" class="pa-4" v-html="html" :class="textClass">
      </v-card-text>
      <v-card-text v-else class="pa-4" :class="textClass">{{
        text
      }}</v-card-text>
      <slot />
      <v-card-actions class="pa-4 pt-0 justify-end">
        <v-btn @click="close">{{ $t("globals.ok") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    titleColor: String,
    titleIcon: String,
    textClass: String,
    blurBackground: Boolean,
    text: String,
    html: String,
    width: Number,
    model: Boolean,
    showOnce: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.blurBackground) {
      const collection = document.getElementsByClassName("v-application--wrap");
      collection[0].classList.add("blur");
    }
  },
  computed: {
    internalModel: {
      get() {
        if (this.showOnce) {
          if (localStorage.getItem("benefitCardsWarningShown") === "true") {
            return false;
          }
        }
        return this.model;
      },
      set(v) {
        this.close(v);
      },
    },
  },
  methods: {
    close(v) {
      const collection = document.getElementsByClassName("v-application--wrap");
      collection[0].classList.remove("blur");
      this.dialogHasBeenShown = true;
      if (this.showOnce) {
        localStorage.setItem("benefitCardsWarningShown", true);
      }
      this.$emit("close", v);
    },
  },
};
</script>
